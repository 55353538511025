<template>
    <!-- Two sibling root nodes so that the #app grid container has two children: main and footer -->
    <main class="main">
        <section class="hero">
            <div class="container">
                <div class="row">
                    <div class="headline-box">
                        <img class="svg" alt="Python logo" src="@/assets/img/python-logo.svg">
                        <h1 class="headline">Build, compile and install Python from source code</h1>
                    </div>
                    <ul class="list-subheadline">
                        <li>
                            <h2 class="subheadline">
                                <span>&check;</span> Compile, build and install Python 3.14, 3.13, 3.12, 3.11,
                                3.10, 3.9, 3.8, 3.7, 3.6 or 2.7 binaries from source code
                            </h2>
                        </li>
                        <li>
                            <h2 class="subheadline">
                                <span>&check;</span> Use the generated copy &amp; paste bash script
                            </h2>
                        </li>
                        <li>
                            <h2 class="subheadline">
                                <span>&check;</span> Don’t worry, the shipped Python in your Linux box is going to keep
                                untouched
                            </h2>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="control">
            <div class="container">
                <div class="row">
                    <div class="block">
                        <h3 class="sub2headline">Choose Python</h3>
                        <BaseButtonGroup @triggerClick="setPythonFam($event)" :options="Object.keys(data.python)"
                                         :value="curr.pythonFam"/>
                        <BaseSelect
                            v-model="curr.pythonVer"
                            :options="selectData.pythonVer"
                            :label="data.ui.select.selectPythonVer.label"
                            @update:modelValue="calcPrefix"
                        />
                    </div>

                    <div class="block">
                        <h3 class="sub2headline">Python Build Configuration Options</h3>
                        <BaseInput
                            v-model="curr.prefixFolder"
                            :label="data.ui.input.prefix.label"
                            :placeholder="data.ui.input.prefix.placeholder"
                        />
                        <BaseToggle
                            v-model="curr.hasUseAllCpus"
                            :label="data.ui.checkbox.hasUseAllCpus.label"
                        />
                        <BaseToggle
                            v-model="curr.hasSpeedOptimization"
                            :label="data.ui.checkbox.hasSpeedOptimization.label"
                        />
                        <BaseToggle
                            v-model="curr.hasSharedLibrary"
                            :label="data.ui.checkbox.hasSharedLibrary.label"
                        />
                        <BaseToggle
                            v-model="curr.hasSqliteSupport"
                            :label="data.ui.checkbox.hasSqliteSupport.label"
                        />
                        <BaseToggle
                            v-model="curr.hasOpensslSupport"
                            :label="data.ui.checkbox.hasOpensslSupport.label"
                        />
                        <BaseToggle
                            v-model="curr.hasDebug"
                            :label="data.ui.checkbox.hasDebug.label"
                        />
                        <BaseToggle
                            v-model="curr.hasTestBinary"
                            :label="data.ui.checkbox.hasTestBinary.label"
                        />
                    </div>

                    <div class="block">
                        <h3 class="sub2headline">Python 3.13+ related options</h3>
                        <BaseToggle
                            v-model="curr.hasDisableGil"
                            :label="data.ui.checkbox.hasDisableGil.label"
                        />
                        <BaseToggle
                            v-model="curr.hasExperimentalJit"
                            :label="data.ui.checkbox.hasExperimentalJit.label"
                        />
                    </div>


                    <div class="block">
                        <h3 class="sub2headline">Post installation stuff</h3>
                        <BaseToggle
                            v-model="curr.hasSomeLinks"
                            :label="data.ui.checkbox.hasSomeLinks.label"
                        />
                    </div>


                    <div class="block">
                        <h3 class="sub2headline">Python Package Management</h3>
                        <BaseToggle
                            v-model="curr.hasUpdatePip"
                            :label="data.ui.checkbox.hasUpdatePip.label"
                        />
                        <BaseToggle
                            v-model="curr.hasInstallPoetry"
                            :label="data.ui.checkbox.hasInstallPoetry.label"
                        />
                        <BaseToggle
                            v-model="curr.hasInstallUv"
                            :label="data.ui.checkbox.hasInstallUv.label"
                        />
                    </div>

                    <div class="block">
                        <h3 class="sub2headline">Linux System Dependencies</h3>
                        <BaseToggle
                            v-model="curr.hasInstallPackages"
                            :label="data.ui.checkbox.hasInstallPackages.label"
                        />
                        <div v-if="curr.hasInstallPackages">
                            <BaseButtonGroup @triggerClick="setOsFam($event)" :options="Object.keys(data.os)"
                                             :value="curr.osFam" :disabled="!curr.hasInstallPackages"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="code">
            <div class="container">
                <div class="row">
                    <CodePlace :code="code" class="codePlace"
                               title="Copy & Paste bash script - Build, compile and install Python from source code."/>
                </div>
            </div>
        </section>
    </main>

    <!-- Footer is now a sibling of main, so the grid container (#app) has two children -->
    <Footer class="footer"/>
</template>

<script>
import {config, initValue, script} from '@/dassets/config';
import BaseInput from "@/components/BaseInput";
import BaseSelect from "@/components/BaseSelect";
import BaseButtonGroup from "@/components/BaseButtonGroup";
import BaseToggle from "@/components/BaseToggle";
import CodePlace from "@/components/CodePlace";
import Footer from "@/components/Footer";

export default {
    name: 'App',
    components: {
        BaseInput,
        BaseSelect,
        BaseButtonGroup,
        BaseToggle,
        CodePlace,
        Footer,
    },
    data() {
        return {
            data: config,
            code: "",
            selectData: {
                pythonVer: initValue.pythonVerSelectOptions,
                osVer: initValue.osVerSelectOptions,
            },
            curr: {
                osFam: initValue.osFam,
                osVer: initValue.osVer,
                pythonFam: initValue.pythonFam,
                pythonVer: initValue.pythonVer,
                url: null,
                hasComments: initValue.hasComments,
                hasInstallPackages: initValue.hasInstallPackages,
                hasUseAllCpus: initValue.hasUseAllCpus,
                hasSharedLibrary: initValue.hasSharedLibrary,
                hasOpensslSupport: initValue.hasOpensslSupport,
                hasSqliteSupport: initValue.hasSqliteSupport,
                hasSpeedOptimization: initValue.hasSpeedOptimization,
                hasDisableGil: initValue.hasDisableGil,
                hasExperimentalJit: initValue.hasExperimentalJit,
                hasTestBinary: initValue.hasTestBinary,
                hasSomeLinks: initValue.hasSomeLinks,
                hasUpdatePip: initValue.hasUpdatePip,
                hasInstallPoetry: initValue.hasInstallPoetry,
                hasInstallUv: initValue.hasInstallUv,
                // New property for debug builds
                hasDebug: initValue.hasDebug,
                prefixFolder: initValue.prefixFolder,
                sharedLibraryFolder: initValue.sharedLibraryFolder,
            },
        }
    },
    methods: {
        calcUrl() {
            this.curr.url = this.data.python[this.curr.pythonFam].ver[this.curr.pythonVer].url;
        },
        calcPrefix() {
            this.curr.prefixFolder = `${initValue.prefixBaseFolder}${this.curr.pythonVer}/`;
        },
        setOsFam(val) {
            const ver_arr = Object.keys(this.data.os[val].ver).sort((a, b) => a - b).reverse();
            this.selectData.osVer = ver_arr;
            this.curr.osFam = val;
            this.curr.osVer = ver_arr[0];
        },
        setPythonFam(val) {
            const ver_arr = Object.keys(this.data.python[val].ver).sort((a, b) => a - b);
            this.selectData.pythonVer = ver_arr;
            this.curr.pythonFam = val;
            this.curr.pythonVer = ver_arr[0];
            this.calcPrefix();
        },
        generateCode() {
            this.calcUrl();
            const NL = "\n";
            const DOUBLE_NL = "\n\n";
            let code_arr = [];

            function chooseData(data_feature, curr_version) {
                return data_feature[curr_version] || data_feature.common;
            }

            if (this.curr.hasInstallPackages) {
                code_arr.push(chooseData(script.packages, this.curr.osFam).join(NL));
                code_arr.push(DOUBLE_NL);
            }

            code_arr.push(script.prepare.join(NL));
            code_arr.push(DOUBLE_NL);

            let compile_configure = script.compile_configure.join(NL);
            if (this.curr.hasSpeedOptimization) {
                compile_configure += " " + script.compile_configure__hasSpeedOptimization.join(' ');
            }
            if (this.curr.hasDisableGil) {
                compile_configure += " " + script.compile_configure__hasDisableGil.join(' ');
            }
            if (this.curr.hasExperimentalJit) {
                compile_configure += " " + script.compile_configure__hasExperimentalJit.join(' ');
            }
            if (this.curr.hasSharedLibrary) {
                compile_configure += " " + script.compile_configure__hasSharedLibrary.join(' ');
            }
            if (this.curr.hasSqliteSupport) {
                compile_configure += " " + script.compile_configure__hasSqliteSupport.join(' ');
            }
            if (this.curr.hasOpensslSupport && script.compile_configure__hasOpensslSupport.length) {
                compile_configure += " " + script.compile_configure__hasOpensslSupport.join(' ');
            }
            if (this.curr.hasDebug) {
                compile_configure += " " + script.compile_configure__hasDebug.join(' ');
            }
            code_arr.push(compile_configure);
            code_arr.push(NL);

            let compile_make = script.compile_make.join(NL);
            if (this.curr.hasUseAllCpus) {
                compile_make += " " + script.compile_make__hasUseAllCpus.join(' ');
            }
            code_arr.push(compile_make);
            code_arr.push(NL);

            if (this.curr.hasTestBinary) {
                let compile_testBinaries = script.compile_testBinaries.join(NL);
                if (this.curr.hasUseAllCpus) {
                    compile_testBinaries += " " + script.compile_testBinaries__hasUseAllCpus.join(' ');
                }
                code_arr.push(compile_testBinaries);
                code_arr.push(NL);
            }

            code_arr.push(script.compile_post.join(NL));
            code_arr.push(DOUBLE_NL);

            if (this.curr.hasSomeLinks) {
                code_arr.push(script.links.join(NL));
                code_arr.push(NL);
            }

            if (this.curr.hasUpdatePip) {
                code_arr.push(chooseData(script.pipUpdate, this.curr.pythonFam).join(NL));
                code_arr.push(DOUBLE_NL);

            }
            if (this.curr.hasInstallPoetry) {
                code_arr.push(chooseData(script.poetryInstall, this.curr.pythonFam).join(NL));
                code_arr.push(DOUBLE_NL);

            }

            if (this.curr.hasInstallUv) {
                code_arr.push(script.uvInstall.common.join(NL));
                code_arr.push(DOUBLE_NL);
            }

            let codeContent = code_arr.join("");
            const replacePattern_arr = [
                ["[[pythonFam]]", this.curr.pythonFam],
                ["[[pythonVer]]", this.curr.pythonVer],
                ["[[prefix]]", this.curr.prefixFolder.replace(/\/+$/, '')],
                ["[[url]]", this.curr.url],
            ];
            replacePattern_arr.forEach(item => {
                codeContent = codeContent.replaceAll(item[0], item[1]);
            });
            this.code = codeContent.replace(/\n+$/, '');
        },
    },
    mounted() {
        this.generateCode();
    },
    watch: {
        curr: {
            handler() {
                this.generateCode();
            },
            deep: true,
        }
    },
};
</script>

<style lang="scss" scoped>
/* Your additional component styles (if any) */
</style>
